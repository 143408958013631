import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { Directive, ContentChild, ContentChildren, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { ComplexBase, setValue, Template, ArrayBase, ComponentBase, ComponentMixins } from '@syncfusion/ej2-angular-base';
import { FileManager, DetailsView, NavigationPane, LargeIconsView, Toolbar, ContextMenu, BreadCrumbBar, Virtualization } from '@syncfusion/ej2-filemanager';
export * from '@syncfusion/ej2-filemanager';
import { CommonModule } from '@angular/common';
const _c0 = ["template"];
let input = ['align', 'cssClass', 'disabled', 'htmlAttributes', 'id', 'name', 'overflow', 'prefixIcon', 'showAlwaysInPopup', 'showTextOn', 'suffixIcon', 'tabIndex', 'template', 'text', 'tooltipText', 'type', 'visible', 'width'];
let outputs$1 = [];
class ToolbarItemDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$1);
    this.directivePropList = input;
  }
}
ToolbarItemDirective.ɵfac = function ToolbarItemDirective_Factory(t) {
  return new (t || ToolbarItemDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
ToolbarItemDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ToolbarItemDirective,
  selectors: [["e-toolbaritem"]],
  contentQueries: function ToolbarItemDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
    }
  },
  inputs: {
    align: "align",
    cssClass: "cssClass",
    disabled: "disabled",
    htmlAttributes: "htmlAttributes",
    id: "id",
    name: "name",
    overflow: "overflow",
    prefixIcon: "prefixIcon",
    showAlwaysInPopup: "showAlwaysInPopup",
    showTextOn: "showTextOn",
    suffixIcon: "suffixIcon",
    tabIndex: "tabIndex",
    template: "template",
    text: "text",
    tooltipText: "tooltipText",
    type: "type",
    visible: "visible",
    width: "width"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
__decorate([Template()], ToolbarItemDirective.prototype, "template", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToolbarItemDirective, [{
    type: Directive,
    args: [{
      selector: 'e-toolbaritems>e-toolbaritem',
      inputs: input,
      outputs: outputs$1,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    template: [{
      type: ContentChild,
      args: ['template']
    }]
  });
})();
/**
 * ToolbarItem Array Directive
 * @private
 */
class ToolbarItemsDirective extends ArrayBase {
  constructor() {
    super('toolbaritems');
  }
}
ToolbarItemsDirective.ɵfac = function ToolbarItemsDirective_Factory(t) {
  return new (t || ToolbarItemsDirective)();
};
ToolbarItemsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ToolbarItemsDirective,
  selectors: [["e-toolbaritems"]],
  contentQueries: function ToolbarItemsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ToolbarItemDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToolbarItemsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-filemanager>e-toolbaritems',
      queries: {
        children: new ContentChildren(ToolbarItemDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs = ['ajaxSettings', 'allowDragAndDrop', 'allowMultiSelection', 'contextMenuSettings', 'cssClass', 'detailsViewSettings', 'enableHtmlSanitizer', 'enablePersistence', 'enableRangeSelection', 'enableRtl', 'enableVirtualization', 'fileSystemData', 'height', 'locale', 'navigationPaneSettings', 'path', 'popupTarget', 'rootAliasName', 'searchSettings', 'selectedItems', 'showFileExtension', 'showHiddenItems', 'showItemCheckBoxes', 'showThumbnail', 'sortBy', 'sortComparer', 'sortOrder', 'toolbarItems', 'toolbarSettings', 'uploadSettings', 'view', 'width'];
const outputs = ['beforeDelete', 'beforeDownload', 'beforeFolderCreate', 'beforeImageLoad', 'beforeMove', 'beforePopupClose', 'beforePopupOpen', 'beforeRename', 'beforeSend', 'created', 'delete', 'destroyed', 'failure', 'fileDragStart', 'fileDragStop', 'fileDragging', 'fileDropped', 'fileLoad', 'fileOpen', 'fileSelect', 'fileSelection', 'folderCreate', 'menuClick', 'menuClose', 'menuOpen', 'move', 'popupClose', 'popupOpen', 'rename', 'search', 'success', 'toolbarClick', 'toolbarCreate', 'uploadListCreate'];
const twoWays = [''];
/**
  * Represents the Essential JS 2 Angular FileManager Component.
 * ```html
 * <ejs-filemanager showThumbnail='false'></ejs-filemanager>
 * ```
 */
let FileManagerComponent = class FileManagerComponent extends FileManager {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['toolbarItems'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    try {
      let mod = this.injector.get('FileManagerDetailsView');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('FileManagerNavigationPane');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('FileManagerLargeIconsView');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('FileManagerToolbar');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('FileManagerContextMenu');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('FileManagerBreadCrumbBar');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('FileManagerVirtualization');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    this.registerEvents(outputs);
    this.addTwoWay.call(this, twoWays);
    setValue('currentInstance', this, this.viewContainerRef);
    this.context = new ComponentBase();
  }
  ngOnInit() {
    this.context.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.context.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.context.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childToolbarItems;
    this.context.ngAfterContentChecked(this);
  }
};
FileManagerComponent.ɵfac = function FileManagerComponent_Factory(t) {
  return new (t || FileManagerComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
FileManagerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: FileManagerComponent,
  selectors: [["ejs-filemanager"]],
  contentQueries: function FileManagerComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ToolbarItemsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childToolbarItems = _t.first);
    }
  },
  inputs: {
    ajaxSettings: "ajaxSettings",
    allowDragAndDrop: "allowDragAndDrop",
    allowMultiSelection: "allowMultiSelection",
    contextMenuSettings: "contextMenuSettings",
    cssClass: "cssClass",
    detailsViewSettings: "detailsViewSettings",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRangeSelection: "enableRangeSelection",
    enableRtl: "enableRtl",
    enableVirtualization: "enableVirtualization",
    fileSystemData: "fileSystemData",
    height: "height",
    locale: "locale",
    navigationPaneSettings: "navigationPaneSettings",
    path: "path",
    popupTarget: "popupTarget",
    rootAliasName: "rootAliasName",
    searchSettings: "searchSettings",
    selectedItems: "selectedItems",
    showFileExtension: "showFileExtension",
    showHiddenItems: "showHiddenItems",
    showItemCheckBoxes: "showItemCheckBoxes",
    showThumbnail: "showThumbnail",
    sortBy: "sortBy",
    sortComparer: "sortComparer",
    sortOrder: "sortOrder",
    toolbarItems: "toolbarItems",
    toolbarSettings: "toolbarSettings",
    uploadSettings: "uploadSettings",
    view: "view",
    width: "width"
  },
  outputs: {
    beforeDelete: "beforeDelete",
    beforeDownload: "beforeDownload",
    beforeFolderCreate: "beforeFolderCreate",
    beforeImageLoad: "beforeImageLoad",
    beforeMove: "beforeMove",
    beforePopupClose: "beforePopupClose",
    beforePopupOpen: "beforePopupOpen",
    beforeRename: "beforeRename",
    beforeSend: "beforeSend",
    created: "created",
    delete: "delete",
    destroyed: "destroyed",
    failure: "failure",
    fileDragStart: "fileDragStart",
    fileDragStop: "fileDragStop",
    fileDragging: "fileDragging",
    fileDropped: "fileDropped",
    fileLoad: "fileLoad",
    fileOpen: "fileOpen",
    fileSelect: "fileSelect",
    fileSelection: "fileSelection",
    folderCreate: "folderCreate",
    menuClick: "menuClick",
    menuClose: "menuClose",
    menuOpen: "menuOpen",
    move: "move",
    popupClose: "popupClose",
    popupOpen: "popupOpen",
    rename: "rename",
    search: "search",
    success: "success",
    toolbarClick: "toolbarClick",
    toolbarCreate: "toolbarCreate",
    uploadListCreate: "uploadListCreate"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function FileManagerComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
FileManagerComponent = __decorate([ComponentMixins([ComponentBase])], FileManagerComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileManagerComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-filemanager',
      inputs: inputs,
      outputs: outputs,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childToolbarItems: new ContentChild(ToolbarItemsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, null);
})();

/**
 * NgModule definition for the FileManager component.
 */
class FileManagerModule {}
FileManagerModule.ɵfac = function FileManagerModule_Factory(t) {
  return new (t || FileManagerModule)();
};
FileManagerModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FileManagerModule
});
FileManagerModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileManagerModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [FileManagerComponent, ToolbarItemDirective, ToolbarItemsDirective],
      exports: [FileManagerComponent, ToolbarItemDirective, ToolbarItemsDirective]
    }]
  }], null, null);
})();
const DetailsViewService = {
  provide: 'FileManagerDetailsView',
  useValue: DetailsView
};
const NavigationPaneService = {
  provide: 'FileManagerNavigationPane',
  useValue: NavigationPane
};
const LargeIconsViewService = {
  provide: 'FileManagerLargeIconsView',
  useValue: LargeIconsView
};
const ToolbarService = {
  provide: 'FileManagerToolbar',
  useValue: Toolbar
};
const ContextMenuService = {
  provide: 'FileManagerContextMenu',
  useValue: ContextMenu
};
const BreadCrumbBarService = {
  provide: 'FileManagerBreadCrumbBar',
  useValue: BreadCrumbBar
};
const VirtualizationService = {
  provide: 'FileManagerVirtualization',
  useValue: Virtualization
};
/**
 * NgModule definition for the FileManager component with providers.
 */
class FileManagerAllModule {}
FileManagerAllModule.ɵfac = function FileManagerAllModule_Factory(t) {
  return new (t || FileManagerAllModule)();
};
FileManagerAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: FileManagerAllModule
});
FileManagerAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [DetailsViewService, NavigationPaneService, LargeIconsViewService, ToolbarService, ContextMenuService, BreadCrumbBarService, VirtualizationService],
  imports: [[CommonModule, FileManagerModule], FileManagerModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileManagerAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, FileManagerModule],
      exports: [FileManagerModule],
      providers: [DetailsViewService, NavigationPaneService, LargeIconsViewService, ToolbarService, ContextMenuService, BreadCrumbBarService, VirtualizationService]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { BreadCrumbBarService, ContextMenuService, DetailsViewService, FileManagerAllModule, FileManagerComponent, FileManagerModule, LargeIconsViewService, NavigationPaneService, ToolbarItemDirective, ToolbarItemsDirective, ToolbarService, VirtualizationService };
